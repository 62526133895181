import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { TabsModule } from 'ngx-bootstrap/tabs';

import { ModalCompComponent } from './modal-comp/modal-comp.component';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal'; 

@NgModule({
  declarations: [AppComponent, HomeComponent, ModalCompComponent ],
  imports: [BrowserModule, AppRoutingModule, TabsModule.forRoot(),  ModalModule.forRoot()],
  providers: [BsModalService],
  bootstrap: [AppComponent],
})
export class AppModule {}
