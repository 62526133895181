<!-- Logo -->
<div class="container">
  <div class="row text-center">
    <img class="logo" src="assets/img/logo.png" />
  </div>
</div>

<tabset [justified]="true">
  <!-- # INICIO COMIDA #  -->
  <tab customClass="customClass" heading="Carta">
    <!-- Para llevar -->
    <div class="container py-4">
      <!-- <div class="row">
        <div class="col-12 title">
          <h3 class="font-title">
            Carta Para Llevar
            <a
              href="assets/img/Carta-para-llevar.pdf"
              download="Carta-tierramar-para-llevar.pdf"
            >
              <button type="button" class="btn btn-dark ml-4">
                <i class="fa fa-download pr-2"></i> Descargar
              </button>
            </a>
          </h3>
        </div>
        <div class="col-12"></div>
      </div> -->
      <!-- <div class="row">
        <div class="col-12 my-4 text-center">
          <button
            type="button"
            class="btn btn-primary"
            (click)="openModal(modalTemplate)"
          >
            Mostrar Menú Vispera de San Sebastián
          </button>

          <ng-template #modalTemplate>
            <app-modal-comp [dataFromParent]="modalRef"></app-modal-comp>
          </ng-template>
        </div>
      </div> -->

      <div class="row">
        <div class="col-12 title" style="background-color: lightgrey">
          <h3 class="font-title">Menús</h3>
        </div>
      </div>
      <div class="row font-body">
        <div class="col-8 pr-0">
          Menú del Día
          <span style="font-size: 16px">** Martes a Viernes **</span>
        </div>
        <div class="col-4 text-right font-price pl-0">
          <span> 17,90€ </span>
        </div>
      </div>
      <div class="row font-body">
        <div class="col-8 pr-0">
          Menú de Fin de Semana
          <span style="font-size: 16px">** Viernes Noche a Domingo **</span>
        </div>
        <div class="col-4 text-right font-price pl-0">
          <span> 23,90€ </span>
        </div>
      </div>
    </div>

    <!-- <div class="dropdown-divider pt-3 pb-2"></div> -->

    <!-- Picoteo -->
    <div class="container pb-4">
      <div class="row pb-3">
        <div class="col-8 title">
          <h3 class="font-title">Raciones</h3>
        </div>
        <div class="col-4 pt-3 title text-right">
          <span>Media / Entera</span>
        </div>
      </div>
      <div class="row font-body" *ngFor="let picoteo of data.picoteo">
        <div class="col-8 pr-0" *ngIf="picoteo.visible">
          {{ picoteo.name }}
          <span *ngFor="let allergen of picoteo.allergens" class="pl-1">
            <img
              class="allergen"
              src="{{ 'assets/img/alergenos/' + allergen + '.jpg' }}"
            />
          </span>
        </div>
        <div class="col-4 text-right font-price pl-0" *ngIf="picoteo.visible">
          <span *ngIf="picoteo.price1"> {{ picoteo.price1 }} / </span>
          <span> {{ picoteo.price2 }}€ </span>
        </div>
      </div>
    </div>

    <!-- Mariscos -->
    <div class="container pb-4">
      <div class="row pb-3">
        <div class="col-8 title">
          <h3 class="font-title">Mariscos</h3>
        </div>
        <div class="col-4 pt-3 title text-right">
          <span>Media / Entera</span>
        </div>
      </div>
      <div class="row font-body" *ngFor="let marisco of data.mariscos">
        <div class="col-8 pr-0" *ngIf="marisco.visible">
          {{ marisco.name }}
          <span *ngFor="let allergen of marisco.allergens" class="pl-1">
            <img
              class="allergen"
              src="{{ 'assets/img/alergenos/' + allergen + '.jpg' }}"
            />
          </span>
        </div>
        <div class="col-4 text-right font-price pl-0" *ngIf="marisco.visible">
          <span *ngIf="marisco.price1"> {{ marisco.price1 }} / </span>
          <span> {{ marisco.price2 }}€ </span>
        </div>
      </div>
    </div>

    <!-- Ensaladas -->
    <div class="container pb-4">
      <div class="row pb-3">
        <div class="col-12 title">
          <h3 class="font-title">Ensaladas</h3>
        </div>
      </div>
      <div class="row font-body" *ngFor="let ensalada of data.ensaladas">
        <div class="col-sm-9 col-10 pr-0" *ngIf="ensalada.visible">
          <span class="dish-name">{{ ensalada.name }} &bull; </span>
          {{ ensalada.desc }}
          <span *ngFor="let allergen of ensalada.allergens" class="pl-1">
            <img
              class="allergen"
              src="{{ 'assets/img/alergenos/' + allergen + '.jpg' }}"
            />
          </span>
        </div>
        <div
          class="col-sm-3 col-2 text-right font-price"
          *ngIf="ensalada.visible"
        >
          <span> {{ ensalada.price }}€ </span>
        </div>
      </div>
    </div>

    <!-- Especialidades de la Casa-->
    <div class="container pb-4">
      <div class="row pb-3">
        <div class="col-8 title">
          <h3 class="font-title">Especialidades de la Casa</h3>
        </div>
        <div class="col-4 pt-3 title text-right">
          <span>Media / Entera</span>
        </div>
      </div>
      <div class="row font-body" *ngFor="let carne of data.carnes_pescados">
        <div class="col-8 col-lg-9 pr-0" *ngIf="carne.visible">
          {{ carne.name }}
          <span *ngFor="let allergen of carne.allergens" class="pl-1">
            <img
              class="allergen"
              src="{{ 'assets/img/alergenos/' + allergen + '.jpg' }}"
            />
          </span>
        </div>
        <div
          class="col-4 col-lg-3 text-right font-price pl-0"
          *ngIf="carne.visible"
        >
          <span *ngIf="carne.price1"> {{ carne.price1 }} / </span>
          <span> {{ carne.price2 }}€ </span>
        </div>
      </div>
    </div>

    <!-- Sartenazos -->
    <div class="container pb-4">
      <div class="row pb-3">
        <div class="col-12 title">
          <h3 class="font-title">Sartenazos</h3>
        </div>
      </div>
      <div class="row font-body" *ngFor="let sartenazo of data.sartenazos">
        <div class="col-9" *ngIf="sartenazo.visible">
          <span class="dish-name">{{ sartenazo.name }} &bull; </span>
          {{ sartenazo.desc }}
          <span *ngFor="let allergen of sartenazo.allergens" class="pl-1">
            <img
              class="allergen"
              src="{{ 'assets/img/alergenos/' + allergen + '.jpg' }}"
            />
          </span>
        </div>
        <div class="col-3 text-right font-price" *ngIf="sartenazo.visible">
          <span> {{ sartenazo.price }}€ </span>
        </div>
      </div>
    </div>

    <!-- Postres -->
    <div class="container pb-4">
      <div class="row pb-3">
        <div class="col-12 title">
          <h3 class="font-title">Postres</h3>
        </div>
      </div>
      <div class="row font-body" *ngFor="let postre of data.postres">
        <div class="col-9" *ngIf="postre.visible">
          {{ postre.name }}
          <span *ngFor="let allergen of postre.allergens" class="pl-1">
            <img
              class="allergen"
              src="{{ 'assets/img/alergenos/' + allergen + '.jpg' }}"
            />
          </span>
        </div>
        <div class="col-3 text-right font-price" *ngIf="postre.visible">
          <span> {{ postre.price }}€ </span>
        </div>
      </div>
    </div>

    <div class="text-center font-pan pb-2">
      <span class="pr-2">** Ración de Pan</span>
      <span style="color: #a37d4d">0,90€</span>
    </div>

    <div class="dropdown-divider pt-2 pb-2"></div>

    <!-- Alergenos-->
    <div class="container pb-3">
      <div class="row">
        <div class="col-12">
          <h3 class="font-body text-center" style="font-size: 16px">
            Alérgenos
          </h3>
        </div>
      </div>
      <div class="row font-allergen text-center">
        <span
          class="col-2 col-md-1 text-center p-1"
          *ngFor="let alergeno of filteredAllergens"
        >
          <div *ngIf="alergeno.visible">
            <img
              class="allergen"
              src="{{ 'assets/img/alergenos/' + alergeno.img + '.jpg' }}"
            />
            <br />
            <i>{{ alergeno.name }}</i>
          </div>
        </span>
      </div>
    </div>
  </tab>
  <!-- # FIN COMIDA #-->

  <!-- # INICIO BEBIDA #  -->
  <tab customClass="customClass" heading="Bebida">
    <!-- Vinos -->
    <div class="container pb-4">
      <div class="row pb-3">
        <div class="col-12 title">
          <h3 class="font-title">Vinos</h3>
        </div>
      </div>
      <div *ngFor="let key of objectKeys(data.vinos)">
        <div class="row">
          <div class="col-7" style="border-bottom: 1px solid">
            <h5 class="font-price">
              {{ key | uppercase }}
            </h5>
          </div>
          <div class="col-2 font-body">Copa</div>
          <div class="col-2 font-body">Botella</div>
        </div>

        <div
          class="row font-body"
          *ngFor="let vino of data.vinos[key]; let last = last"
        >
          <div class="col-7" *ngIf="vino.visible">
            {{ vino.name }}
          </div>
          <div class="col-2 text-right font-price" *ngIf="vino.visible">
            <span *ngIf="vino.price1"> {{ vino.price1 }}€ </span>
          </div>
          <div
            class="col-2 text-right font-price"
            [ngClass]="{ lastchild: last }"
            *ngIf="vino.visible"
          >
            <span> {{ vino.price2 }}€ </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Cervezas y Refrescos -->
    <div class="container pb-4">
      <div class="row pb-3">
        <div class="col-12 title">
          <h3 class="font-title">Cervezas y Refrescos</h3>
        </div>
      </div>
      <div
        class="row font-body"
        *ngFor="let cerveza of data.cervezas_refrescos"
      >
        <div class="col-8" *ngIf="cerveza.visible">
          {{ cerveza.name }}
        </div>
        <div class="col-4 pr-4 text-right font-price" *ngIf="cerveza.visible">
          <span> {{ cerveza.price2 }}€ </span>
        </div>
      </div>
    </div>
  </tab>
  <!-- # FIN BEBIDA #  -->
</tabset>
