<div class="modal-header">
  <h4 class="modal-title pull-left"></h4>
  <button
    type="button"
    class="btn-close close pull-right"
    aria-label="Close"
    (click)="modalRef?.hide()"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body text-center">
  <img
    id="myImg"
    src="assets/img/menu-vispera-2024.jpg"
    alt="San Sebastian"
    style="width: 80%"
  />
</div>
