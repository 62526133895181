import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import jsonData from '../data/data.json';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public modalRef: BsModalRef;
  public data: any = jsonData;

  objectKeys = Object.keys;
  public filteredAllergens: any = jsonData["alergenos"].filter(alergeno => alergeno.visible)
  constructor(private modalService: BsModalService) {
    //console.log(this.data);
  }

  @ViewChild('modalTemplate', { static: true }) content: TemplateRef<any>;

  openModal(modalTemplate: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modalTemplate,
      {
        class: 'modal-dialogue-centered modal-lg',
        backdrop: 'static',
        keyboard: true
      }
    );
  }
  ngOnInit(): void {
    // this.openModal(this.content)
  }
}
